import React, { Component } from 'react'
import AOS from 'aos'
import Constants from "../utils/Constants";

export class ThankYou extends Component {

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            loading: props.loading,
            talent_name: props.talent_name
        }

        this.handleNavigate = this.handleNavigate.bind(this);
    }

    async componentDidMount() {
        document.body.style.backgroundColor = "black";
        document.body.className = "content-login";
        AOS.init({ duration: 1000 });
    }

    async componentDidUpdate(prevProps, prevState) {

        if (this.state.user !== this.props.user || this.state.loading !== this.props.loading) {
            this.state.user = this.props.user;
            this.state.loading = this.props.loading;

            this.setState(this.state, () => { });
        }
    }

    handleNavigate(navigation, talent_name) {
        return this.props.NavigateTo(navigation, talent_name);
    }

    render() {
        return (
            <section className="section-login">
                <div className="container px-4">
                    <div className="d-flex justify-content-center align-items-center m-h-100">
                        <div>
                            <div className="row mt-4 mb-4" data-aos="fade-up">
                                <div className="col-12 text-center">
                                    <img onClick={() => this.handleNavigate(Constants.Navigation.Welcome)} src="assets/img/40_stanMailLogo_416x419.png" alt="" className="Link w-logo-thank-you" />
                                </div>
                            </div>
                            <div className="row mt-4 mb-4">
                                <div className="col-12 text-center">
                                    <div id="handle" className="text-white fw-bold handle" data-aos="fade-up" data-aos-delay="200">
                                        Thank you!
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 mb-4">
                                <div className="col-12 text-center">
                                    <div className="text-white fw-bold thank-you" data-aos="fade-up" data-aos-delay="200">
                                        Please share any parting words with your fans
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default ThankYou
