import API from "@aws-amplify/api";
import {Auth} from "aws-amplify";

async function getInit () {
    await Auth.currentAuthenticatedUser({bypassCache: true});
    const currentSession = await Auth.currentSession();

    if (currentSession && currentSession.idToken && currentSession.idToken.jwtToken) {

        const jwtToken = currentSession.idToken.jwtToken;

        let myInit = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        }

        return myInit;
    }

    return null;
}

const get = async (path, apiName =  'ApiTW')=> {

    let result;

    try {

        const myInit = await getInit();

        if (myInit) {
            result = await API.get(apiName, path, myInit);
        }

    } catch (e) {

        console.log(e);

    }

    return result;
};

const post = async (path, body, apiName =  'ApiTW')=> {

    let result;

    try {

        const myInit = await getInit();

        myInit.body = body;

        if (myInit) {
            result = await API.post(apiName, path, myInit);
        }

    } catch (e) {

    }

    return result;
};

const del = async (path, body, apiName =  'ApiTW')=> {

    let result;

    try {

        const myInit = await getInit();

        myInit.body = body;

        if (myInit) {
            result = await API.del(apiName, path, myInit);
        }

    } catch (e) {

    }

    return result;
};

export default {
    get,
    post,
    del
};
