import RestAPI from "../utils/api";

const getContents = async (campaign, name) => {

    try {

        const path = '/content/getContentsByAuthorization';

        let body = {
            params: {
                "campaignId": campaign
            }
        }

        if (name) {
            body.params.name = name;
        }

        const response = await RestAPI.post(path, body);

        let data = (response && response.data && response.data.length > 0) ? response.data : [];

        return data;

    } catch (e) {

        console.log(e);

    }

    return null;
};

const getDatas = async (campaign) => {

    try {

        const path = '/content/getDataByAuthorization';

        const body = {
            params: {
                "campaignId": campaign
            }
        }

        const response = await RestAPI.post(path, body);

        let data = (response && response.data && response.data.length > 0) ? response.data : [];

        return data;

    } catch (e) {

        console.log(e);

    }

    return null;
};



export default {
    getDatas,
    getContents
};
