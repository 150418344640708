/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tuslC0Pgc",
    "aws_user_pools_web_client_id": "5r8ibd7liji510r900qfusp4lq",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1whjt17jde.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
