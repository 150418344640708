import React, { Component } from "react";
import AOS from "aos";
import Constants from "../utils/Constants";
import $ from 'jquery'

export class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            loading: props.loading,
            talent_name: props.talent_name
        }

        if (this.state.user) {
            this.state.loading = false;
        }

        this.handleNavigate = this.handleNavigate.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.state.tweetData = [];
        this.state.page = 0;
        this.state.touchStart = null;
        this.state.touchEnd = null;

    }

    onTouchStart(e) {
        this.state.touchEnd = null;
        this.state.touchStart = e.targetTouches[0].clientX;
        this.setState(this.state, () => { });
    }

    onTouchMove(e) {
        this.state.touchEnd = e.targetTouches[0].clientX;
        this.setState(this.state, () => { });
    }

    onTouchEnd() {
        if (!this.state.touchStart || !this.state.touchEnd) return
        if (this.state.touchEnd < this.state.touchStart) {
            this.state.isSetData = true;
            this.setState(this.state, () => { });
            $("#box-english").css('opacity', 0);
            $("#box-native").css('opacity', 0);
            if (this.state.page < this.state.tweetData.length - 1) {
                this.state.page++;
                this.setData();
            } else {
                this.state.page = this.state.tweetData.length;
                this.state.isSetData = false;
                this.handleNavigate(Constants.Navigation.ThankYou, this.state.talent_name);
            }
        }

        if (this.state.touchEnd > this.state.touchStart) {
            this.state.isSetData = true;
            this.setState(this.state, () => { });
            if (this.state.page >= 1) {
                $("#box-english").css('opacity', 0);
                $("#box-native").css('opacity', 0);
                this.state.page--;
                this.setData();
            } else {
                this.state.page = 0;
                this.state.isSetData = false;
            }
        }

        this.setState(this.state, () => { });

    }

    setData() {

        $("#next").css("pointer-events", "none");
        $("#prev").css("pointer-events", "none");

        if (!this.state.page) {
            $("#prev").css('opacity', 0);
        } else {
            $("#prev")
                .fadeIn(300)
                .animate({
                    'opacity': 1
                }, 500);
        }

        this.state.isSetData = true;
        this.setState(this.state, () => { });

        if (this.state && this.state.tweetData.length > 0) {

            $("#box-english").removeClass('margin-top-220-important');

            if (!this.state.tweetData[this.state.page].tweet_text_translation && !this.state.tweetData[this.state.page].tweet_text_native) {
                this.state.isSetData = false;
                this.setState(this.state, () => { });
            }

            //set Data
            if (this.state.tweetData[this.state.page] && this.state.tweetData[this.state.page].original_language && this.state.tweetData[this.state.page].original_language.toLowerCase() !== this.state.tweetData[this.state.page].translated_language.toLowerCase()) {
                //match Hashtag
                var regexpHashtag = new RegExp(/#[a-zA-Z\d]+/g);
                var hashtag = this.state.tweetData[this.state.page].tweet_text_native.match(regexpHashtag);
                var result = this.state.tweetData[this.state.page].tweet_text_native;
                if (hashtag && hashtag.length > 0) {
                    hashtag.map(item => {
                        result = result.replace(item, '<span className="color-hashtags fw-bold">' + item + '</span>');
                    });
                }

                //match Mention
                var regexpMention = new RegExp(/@[a-zA-Z\d]+/g);
                var mention = result.match(regexpMention);
                if (mention && mention.length > 0) {
                    mention.map(item => {
                        result = result.replace(item, '<span className="color-hashtags fw-bold">' + item + '</span>');
                    });
                }

                let language = this.state.tweetData[this.state.page].original_language.length > 0 ?
                    this.state.tweetData[this.state.page].original_language.charAt(0).toUpperCase() + this.state.tweetData[this.state.page].original_language.slice(1).toLowerCase()
                    : '';

                let translated_language = this.state.tweetData[this.state.page].translated_language.length > 0 ?
                    this.state.tweetData[this.state.page].translated_language.charAt(0).toUpperCase() + this.state.tweetData[this.state.page].translated_language.slice(1).toLowerCase()
                    : '';

                $("#box-language").text(language);
                $("#box-language-english").text(translated_language);
                $("#fan_name").text(this.state.tweetData[this.state.page].name);
                $("#fan_handle").text(this.state.tweetData[this.state.page].handle);

                result = window.twemoji.parse(result);

                $('#tweet_native_copy').css('fontSize', 250);
                $('#tweet_native_copy').css('lineHeight', 'normal');
                $('#tweet_native_copy').css('overflow-wrap', 'anywhere');
                document.getElementById('tweet_native_copy').innerHTML = result;

                window.resize_to_fit('#box-native-outer-slide', '#tweet_native_copy', null, null, document.documentElement.clientHeight < document.documentElement.clientWidth ? 25 : 20);

                setTimeout(() => {
                    $("#box-native")
                        .fadeIn(200)
                        .animate({
                            'opacity': 1
                        }, 500);

                    setTimeout(() => {
                        $("#box-native")
                            .animate({
                                'opacity': 0.8
                            }, 500);
                    }, 600);

                    if (this.state.tweetData && this.state.tweetData[this.state.page].tweet_text_translation) {
                        //match Hashtag
                        var regexpHashtag = new RegExp(/#[a-zA-Z\d]+/g);
                        var hashtag = this.state.tweetData[this.state.page].tweet_text_translation.match(regexpHashtag);
                        var result = this.state.tweetData[this.state.page].tweet_text_translation;
                        if (hashtag && hashtag.length > 0) {
                            hashtag.map(item => {
                                result = result.replace(item, '<span className="color-hashtags fw-bold">' + item + '</span>');
                            });
                        }

                        //match Mention
                        var regexpMention = new RegExp(/@[a-zA-Z\d]+/g);
                        var mention = result.match(regexpMention);
                        if (mention && mention.length > 0) {
                            mention.map(item => {
                                result = result.replace(item, '<span className="color-hashtags fw-bold">' + item + '</span>');
                            });
                        }

                        $("#fan_name_en").text(this.state.tweetData[this.state.page].name);
                        $("#fan_handle_en").text(this.state.tweetData[this.state.page].handle);

                        result = window.twemoji.parse(result);

                        $('#tweet_translation').css('fontSize', 250);
                        $('#tweet_translation').css('lineHeight', 'normal');
                        $('#tweet_translation').css('overflow-wrap', 'anywhere');
                        document.getElementById('tweet_translation').innerHTML = result;

                        window.resize_to_fit('#box-english-outer-slide', '#tweet_translation', null, null, document.documentElement.clientHeight < document.documentElement.clientWidth ? 35 : 30);

                        setTimeout(() => {
                            $("#box-english")
                                .fadeIn(1000)
                                .animate({
                                    'opacity': 1
                                }, 500);
                            this.state.isSetData = false;
                            this.setState(this.state, () => { });

                            $("#next").css("pointer-events", "");
                            $("#prev").css("pointer-events", "");

                        }, 600);
                    } else {
                        $("#box-english").css('opacity', 0);
                        this.state.isSetData = false;
                        this.setState(this.state, () => { });
                    }

                }, 100);
            } else {
                if (this.state.tweetData[this.state.page].tweet_text_native) {
                    //match Hashtag
                    var regexpHashtag = new RegExp(/#[a-zA-Z\d]+/g);
                    var hashtag = this.state.tweetData[this.state.page].tweet_text_native.match(regexpHashtag);
                    var result = this.state.tweetData[this.state.page].tweet_text_native;
                    if (hashtag && hashtag.length > 0) {
                        hashtag.map(item => {
                            result = result.replace(item, '<span className="color-hashtags fw-bold">' + item + '</span>');
                        });
                    }

                    //match Mention
                    var regexpMention = new RegExp(/@[a-zA-Z\d]+/g);
                    var mention = result.match(regexpMention);
                    if (mention && mention.length > 0) {
                        mention.map(item => {
                            result = result.replace(item, '<span className="color-hashtags fw-bold">' + item + '</span>');
                        });
                    }

                    let language = this.state.tweetData[this.state.page].original_language.length > 0 ?
                        this.state.tweetData[this.state.page].original_language.charAt(0).toUpperCase() + this.state.tweetData[this.state.page].original_language.slice(1).toLowerCase()
                        : '';

                    $("#box-language-english").text(language);

                    $("#box-english").addClass('margin-top-220-important');

                    $("#fan_name_en").text(this.state.tweetData[this.state.page].name);
                    $("#fan_handle_en").text(this.state.tweetData[this.state.page].handle);

                    result = window.twemoji.parse(result);

                    $('#tweet_translation').css('fontSize', 250);
                    $('#tweet_translation').css('lineHeight', 'normal');
                    $('#tweet_translation').css('overflow-wrap', 'anywhere');
                    document.getElementById('tweet_translation').innerHTML = result;

                    window.resize_to_fit('#box-english-outer-slide', '#tweet_translation', null, null, document.documentElement.clientHeight < document.documentElement.clientWidth ? 35 : 30);

                    setTimeout(() => {
                        $("#box-english")
                            .fadeIn(1000)
                            .animate({
                                'opacity': 1
                            }, 500);
                        this.state.isSetData = false;
                        this.setState(this.state, () => { });
                        $("#box-native").css('opacity', 0);

                        $("#next").css("pointer-events", "");
                        $("#prev").css("pointer-events", "");
                    }, 50);
                } else {
                    $("#box-english").css('opacity', 0);
                    this.state.isSetData = false;
                    this.setState(this.state, () => { });
                }
            }

            window.marginLeftNext();
        }
    }

    next() {
        if (!this.state.isSetData) {
            this.state.isSetData = true;
            $('#box-native-outer').css('background', 'white');
            $('.original_language-spanish').css('border-color', 'white');
            $('.original_language-spanish').css('color', 'white');
            $("#box-english").css('opacity', 0);
            $("#box-native").css('opacity', 0);
            if (this.state.page < this.state.tweetData.length - 1) {
                this.state.page++;
                this.setData();
            } else {
                this.state.page = 0;
                this.state.isSetData = false;
                this.handleNavigate(Constants.Navigation.ThankYou, this.state.talent_name);
            }
            this.setState(this.state, () => { });
        }
    }

    prev() {
        if (!this.state.isSetData) {
            this.state.isSetData = true;
            $('#box-native-outer').css('background', 'white');
            $('.original_language-spanish').css('border-color', 'white');
            $('.original_language-spanish').css('color', 'white');
            if (this.state.page >= 1) {
                $("#box-english").css('opacity', 0);
                $("#box-native").css('opacity', 0);
                this.state.page--;
                this.setData();
            } else {
                this.state.page = 0;
                this.state.isSetData = false;
            }
            this.setState(this.state, () => { });
        }
    }

    async componentDidMount() {

        document.body.style.backgroundColor = "#D80065"
        document.body.className = "content-background";
        AOS.init({ duration: 1000 });

        this.state.talent_name = this.state.talent_name || this.state.user.attributes.name;

        if (this.state.user && this.state.user.attributes) {
            let tweetDataResult = localStorage.getItem(this.state.talent_name) ? JSON.parse(localStorage.getItem(this.state.talent_name)) : [];
            this.state.tweetData = tweetDataResult.sort((a, b) => (a.order || 1000) > (b.order || 1000) ? 1 : -1);
            this.setState(this.state, () => { });
        }

        // if (window.isMobile.any() && document.documentElement.clientHeight < document.documentElement.clientWidth) {
        //     $('#next').css('opacity', 0);
        //     $('#prev').css('opacity', 0);
        // }
        // else {
        $("#next")
            .fadeIn(300)
            .animate({
                'opacity': 1
            }, 500);

        // $("#prev")
        //     .fadeIn(300)
        //     .animate({
        //         'opacity': 1
        //     }, 500);
        // }

        this.setData();

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.user !== this.props.user || this.state.loading !== this.props.loading) {
            this.state.user = this.props.user;
            this.state.loading = this.props.loading;

            this.setState(this.state, () => { });
        }
    }

    handleNavigate(navigation, talent_name) {
        return this.props.NavigateTo(navigation, talent_name);
    }

    render() {
        return (
            <section onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove} onTouchEnd={this.onTouchEnd} className="section-content">
                <div className="container">
                    <div className="row margin-top-container">
                        <img src="assets/img/logo-content-empty.png" alt="" className="logo-content-left" />
                        <img onClick={() => this.handleNavigate(Constants.Navigation.Welcome)} src="assets/img/41_stanMailLogo_700x243.png" className="Link logo-content-right" alt="" />
                    </div>
                </div>
                <div>
                    <div id="slide-teet" className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-xl-5 pt-xl-4 helvetica">
                                    <div id="prev" className="visibility col-2 col-md-1 mt-4 d-flex">
                                        <img onClick={this.prev} src="assets/img/prev.png" className="cursor-pointer" alt="" />
                                    </div>
                                    <div id="box-native" className="visibility col-12 col-md-4 mt-4">
                                        <div className="row text-start">
                                            <div id="box-language"
                                                className="fw-bold d-flex justify-content-left align-items-center language-spanish">
                                            </div>
                                        </div>
                                        <div id="box-native-outer-slide" className="inner-tweets-slide">
                                            <div id="fan_name" className="col-6 fw-bold twitter-translate-spanish">Jackie_Cv</div>
                                            <div id="fan_handle" className="twitter-translate-username mb-3">@Jackie_Cv5</div>
                                            <p id="tweet_native_copy">Well, thank you for being a good artist and human being, you deserve all the success ... and because every day you remember who you are and how you started, you never forget that you are a normal person with human concerns and that simplicity. , you know how to thank your fans <strong>#VMAStanMail</strong></p>
                                            <img src="assets/img/icon/Twitter-logo.png" className="twitter-logo" />
                                        </div>
                                    </div>
                                    <div id="box-english" className="visibility col-12 col-md-6 mt-4">
                                        <div className="row">
                                            <div id="box-language-english"
                                                className="text-white fw-bold  d-flex justify-content-left align-items-center language-english">
                                                English
                                            </div>
                                        </div>
                                        <div id="box-english-outer-slide" className="inner-tweets-slide">
                                            <div id="fan_name_en" className="col-6 fw-bold twitter-translate-english">Jackie_Cv</div>
                                            <div id="fan_handle_en" className="twitter-translate-username-english mb-3">@Jackie_Cv5</div>
                                            <p id="tweet_translation">Well, thank you for being a good artist and human being, you deserve all the success ... and because every day you remember who you are and how you started, you never forget that you are a normal person with human concerns and that simplicity. , you know how to thank your fans <strong>#VMAStanMail</strong></p>
                                            <img src="assets/img/icon/Twitter-logo.png" className="twitter-logo" />
                                        </div>
                                    </div>
                                    <div id="next"
                                        className="visibility col-2 col-md-1 mt-4 d-flex">
                                        <img onClick={this.next} src="assets/img/next.png" className="cursor-pointer" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default Content;
