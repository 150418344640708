import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import AOS from 'aos'
import md5 from 'md5';

export class CustomSignIn extends Component {
    constructor(props) {
        super(props)

        this.signIn = this.signIn.bind(this);
        this.handleFormSubmission = this.handleFormSubmission.bind(this);
        this.changeShowPassword = this.changeShowPassword.bind(this);

        let password;


        if (this.props.authState !== AuthState.SignedOut) {
            let search = window.location.search;
            let start = search.indexOf('a=') + 2;
            let end = search.indexOf('&') > search.indexOf('a=') + 4 ? search.indexOf('&') - 1 : search.length;
    
            password = window.location.search.substring(start, end);
        }

        // https://developer.mozilla.org/en-US/docs/Glossary/Base64

        password = password ? atob(password) : null;

        this.state = {
            user: props.user,
            loading: false,
            password,
            noUsername: !password
        }

        if (this.state.password) {
            this.state.username = md5(this.state.password);
            this.signIn();
        }
    }

    changeShowPassword() {
        this.state.showPassword = !this.state.showPassword;

        this.setState(this.state, () => {});
    }

    handleFormSubmission(evt) {
        evt.preventDefault();
        this.signIn();
    }

    async componentDidMount() {

        try {
            document.body.style.backgroundColor = "black"
            document.body.className = "content-login";
            AOS.init({ duration: 1000 });

            const user = await Auth.currentAuthenticatedUser();
            this.state.user = user;

        } catch (e) {
            this.state = { loading: false }
        }

        this.setState(this.state);
    }

    async signIn() {

        const username = this.state.username;
        const password = this.state.password;
        try {

            this.state.loading = true;
            this.state.wrongPassword = false;
            this.setState(this.state);

            await Auth.signIn(username, password);
            await this.props.SetAuthState(AuthState.SignedIn);

            const user = await Auth.currentAuthenticatedUser();
            this.state.user = user;
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                this.setState({ error: "Login failed." });
            } else if (err.code === "NotAuthorizedException") {
                this.setState({ error: "Login failed." });
            } else if (err.code === "UserNotFoundException") {
                this.setState({ error: "Login failed." });
            } else {
                this.setState({ error: "An error has occurred." });
                console.error(err);
            }

            this.state.wrongPassword = true;
        }

        this.state.loading = false;
        this.setState(this.state);
    }

    render() {
        return (
            <section className="section-login">
                <div className="container px-4">
                    <div className="d-flex justify-content-center align-items-center m-h-100">
                        {!this.state.loading ?
                            <div>
                                <div className="row xxl-logo" data-aos="fade-up">
                                    <div className="col-8 col-sm-6 offset-2 offset-sm-3 px-4 py-2">
                                        <img src="assets/img/40_stanMailLogo_416x419.png" alt="" className="w-100" />
                                    </div>
                                </div>
                                <form action="welcome">
                                    {/* {this.state.noUsername ?
                                        <>
                                            <div className="row mt-4">
                                                <div className="col-10 offset-1">
                                                    <div className="text-white fw-bold title mt-2" data-aos="fade-up" data-aos-delay="200">
                                                        Username
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-10 offset-1">
                                                    <div className="input-append mt-2" data-aos="fade-up" data-aos-delay="400">
                                                        <input value={this.state.username} className="ba b--gray br2 pl2 shadow-2" type="text"
                                                            onChange={(e) => this.setState({ username: e.target.value })}></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : null
                                    } */}
                                    <div className="row mt-4">
                                        <div className="col-10 offset-1">
                                            <div className="text-white fw-bold title mt-2" data-aos="fade-up" data-aos-delay="600">
                                                Password
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-10 offset-1">
                                            <div className="input-append mt-2" data-aos="fade-up" data-aos-delay="800">
                                                <span><img onClick={this.changeShowPassword} src={this.state.showPassword? "assets/img/no_eye.png": "assets/img/eye.png"} alt="" className="w-100" /></span>
                                                <input className="ba b--gray br2 pl2 shadow-2" type={this.state.showPassword? "text" : "password"}
                                                    onChange={(e) => this.setState({ password: e.target.value, username: md5(e.target.value) })}></input>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.wrongPassword ?
                                        <div className="row mt-4">
                                            <div className="col-10 offset-1">
                                                <div className="text-warning mt-2 text-center" data-aos="fade-up"
                                                     data-aos-delay="800">
                                                    Wrong Password
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className="row mt-4">
                                        <div className="col-10 offset-1 pt-3 text-center mt-4" data-aos="fade-up" data-aos-delay="600">
                                            <button onClick={this.handleFormSubmission} className="btn-main text-white rounded-pill w-100" type="submit">LOGIN
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            : null}
                    </div>
                </div>
            </section>
        )
    }
}
export default CustomSignIn
