import React, { Component } from 'react'
import AOS from 'aos'
import Constants from "../utils/Constants";

import awsconfig from '../aws-exports';
import ApiService from "../services/ApiService";

const endpoint = awsconfig.aws_cloud_logic_custom && awsconfig.aws_cloud_logic_custom.length > 0 && awsconfig.aws_cloud_logic_custom[0].endpoint && awsconfig.aws_cloud_logic_custom[0].endpoint ? awsconfig.aws_cloud_logic_custom[0].endpoint : null;
const env = endpoint && endpoint.indexOf('main') > 0 ? 'main' : endpoint && endpoint.indexOf('qa') > 0 ? 'qa' : 'test';

let config = require(`../../config/${env}.json`);

export class Welcome extends Component {

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            loading: props.loading,
            talent_name: props.talent_name,
            dataLoading: true
        }

        this.handleNavigate = this.handleNavigate.bind(this);
    }

    async componentDidMount() {

        document.body.style.backgroundColor = "black"
        document.body.className = "content-login";
        AOS.init({ duration: 1000 });

        this.state.talent_name = this.state.talent_name || this.state.user.attributes.name;

        let result = await ApiService.getContents(config.campaign_id.id, this.state.talent_name);

        result = result.filter((item) => item && item.tweet_text_native);

        if (result.length > 0) {
            localStorage.setItem(this.state.talent_name, JSON.stringify(result.sort((a, b) => (a.order || 1000) > (b.order || 1000) ? 1 : -1)));
        }

        this.state.dataLoading = false;
        this.setState(this.state, () => { });
    }

    async componentDidUpdate(prevProps, prevState) {

        if (this.state.user !== this.props.user || this.state.loading !== this.props.loading) {
            this.state.user = this.props.user;
            this.state.loading = this.props.loading;

            this.setState(this.state, () => { });
        }
    }

    handleNavigate(evt) {

        if (evt) {
            evt.preventDefault();
        }

        let tweetDataResult = localStorage.getItem(this.state.talent_name) ? JSON.parse(localStorage.getItem(this.state.talent_name)) : [];

        if (tweetDataResult.length > 0) return this.props.NavigateTo(Constants.Navigation.Content, this.state.talent_name);
        else return this.props.NavigateTo(Constants.Navigation.ThankYou, this.state.talent_name);

    }

    render() {
        return (
            <section className="section-login">
                <div className="container px-4">
                    <div className="d-flex justify-content-center align-items-center m-h-100">
                        <div>
                            <div className="row xxl-logo" data-aos="fade-up">
                                <div className="col-8 col-sm-6 offset-2 offset-sm-3 px-4 py-2">
                                    <img onClick={() => this.props.NavigateTo(Constants.Navigation.Logout)} src="assets/img/40_stanMailLogo_416x419.png" alt="" className="Link w-100" />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-center">
                                    <div className="text-white fw-bold title-welcome" data-aos="fade-up" data-aos-delay="100">
                                        Welcome
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div id="handle" className="text-white fw-bold handle" data-aos="fade-up" data-aos-delay="200">
                                        {this.state.talent_name ? this.state.talent_name : this.state.user && this.state.user.attributes ? this.state.user.attributes.name : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-8 col-sm-6 mx-auto text-center mt-4 btn-get-started-width" data-aos="fade-up" data-aos-delay="300">
                                    {!this.state.dataLoading ?
                                        <button onClick={this.handleNavigate} className="btn-get-started text-white rounded-pill w-100"
                                            type="submit">GET STARTED
                                        </button>
                                        :
                                        <button disabled={true} className="btn-get-started-disabled text-white rounded-pill w-100"
                                            type="submit">Loading...
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Welcome
