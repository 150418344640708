import React, { Component } from 'react'
import AOS from 'aos'
import Constants from "../utils/Constants";

import awsconfig from '../aws-exports';
import ApiService from "../services/ApiService";

const endpoint = awsconfig.aws_cloud_logic_custom && awsconfig.aws_cloud_logic_custom.length > 0 && awsconfig.aws_cloud_logic_custom[0].endpoint && awsconfig.aws_cloud_logic_custom[0].endpoint? awsconfig.aws_cloud_logic_custom[0].endpoint: null;
const env = endpoint && endpoint.indexOf('main') > 0? 'main': endpoint && endpoint.indexOf('qa') > 0? 'qa': 'test';

let config = require(`../../config/${env}.json`);

export class UsersList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            loading: props.loading,
            dataLoading: true
        }

        this.handleNavigate = this.handleNavigate.bind(this);
    }

    async componentDidMount() {

        document.body.style.backgroundColor = "black"
        document.body.className = "content-login";
        AOS.init({ duration: 1000 });

        let result = await ApiService.getDatas(config.campaign_id.id);

        result = result.filter((item) => item && item.Name);

        if (result.length > 0) {
            localStorage.setItem(this.state.user.username, JSON.stringify(result));
        }

        this.state.users = localStorage.getItem(this.state.user.username) ? JSON.parse(localStorage.getItem(this.state.user.username)) : [];

        this.state.dataLoading = false;
        this.setState(this.state, () => { });
    }

    async componentDidUpdate(prevProps, prevState) {

        if (this.state.user !== this.props.user || this.state.loading !== this.props.loading) {
            this.state.user = this.props.user;
            this.state.loading = this.props.loading;

            this.setState(this.state, () => { });
        }
    }

    handleNavigate(talent_name) {
        return this.props.NavigateTo(Constants.Navigation.Welcome, talent_name);
    }

    render() {
        return (
            <section className="section-login">
                <div className="container px-4">
                    <div className="d-flex justify-content-center align-items-center m-h-100">
                        <div>
                            <div className="row xxl-logo " data-aos="fade-up">
                                <div className="col-8 col-sm-6 offset-2 offset-sm-3 px-4 py-2">
                                    <img src="assets/img/40_stanMailLogo_416x419.png" alt="" className="w-100" />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-center">
                                    <div className="text-white fw-bold title-welcome" data-aos="fade-up" data-aos-delay="100">
                                        Select Artist
                                    </div>
                                </div>
                            </div>

                            <div className="list-scroll mt-2" data-aos="fade-up" data-aos-delay="200">
                                {this.state.users ?
                                    [].concat(this.state.users.sort((a, b) => a.Name > b.Name ? 1 : -1)).map((user, itemIndex) => (
                                        user && user.Name ?
                                            <div className="row mt-2">
                                                <div className="col-12 text-center">
                                                    <div onClick={() => this.handleNavigate(user.Name)} id="handle" className="Link F20 text-white fw-bold handle">
                                                        {user.Name}
                                                    </div>
                                                </div>
                                            </div>
                                            : JSON.stringify(user)))
                                    :
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <div id="handle" className="text-white fw-bold handle">
                                                Loading...
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default UsersList
