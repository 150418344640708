const Navigation = {
    'Welcome': 'welcome',
    'Content': 'content',
    'ThankYou': 'thankYou',
    'Logout': 'logout',
    'UsersList': 'usersList'
}

export default {
    Navigation
};
