import './App.css';

import React, { Component } from 'react';

import { AuthState } from '@aws-amplify/ui-components';

import '@aws-amplify/ui-react/styles.css';


//Amplify
import {Amplify, Auth } from 'aws-amplify';

import awsconfig from './aws-exports';
import CustomSignIn from "./components/CustomSignIn";
import Content from "./components/Content";
import Welcome from "./components/Welcome";
import ThankYou from "./components/ThankYou";
import Constants from "./utils/Constants";
import UsersList from "./components/UsersList";

const endpoint = awsconfig.aws_cloud_logic_custom && awsconfig.aws_cloud_logic_custom.length > 0 && awsconfig.aws_cloud_logic_custom[0].endpoint && awsconfig.aws_cloud_logic_custom[0].endpoint? awsconfig.aws_cloud_logic_custom[0].endpoint: null;
const env = endpoint && endpoint.indexOf('main') > 0? 'main': endpoint && endpoint.indexOf('qa') > 0? 'qa': 'test';

let config = require(`../config/${env}.json`);

// Adding Custom API based on env
awsconfig.aws_cloud_logic_custom = awsconfig.aws_cloud_logic_custom || [];
config.aws_cloud_logic_custom = config.aws_cloud_logic_custom || [];
awsconfig.aws_cloud_logic_custom = awsconfig.aws_cloud_logic_custom.concat(config.aws_cloud_logic_custom);

// Amplify init
Amplify.configure(awsconfig);

/* src/App.js */

export class App extends Component {

    constructor(props) {
        super(props)

        this.state = {
            authState: AuthState.SignedOut,
            user: null,
            navigate: null,
            loading: true,
            signUpUsername: '',
            authState: null,
        }
        this.SetUserName = this.SetUserName.bind(this);
        this.SetAuthState = this.SetAuthState.bind(this);
        this.NavigateTo = this.NavigateTo.bind(this);
    }

    SetUserName(Val){
        this.setState({
            signUpUsername: Val,
        })
    }

    SetUser(UserVals){
        this.setState({
            user: UserVals,
        })
    }

    async SetAuthState(Val) {

        this.authState = Val;

        const user = await Auth.currentAuthenticatedUser();
        this.state.user = user;

        if (Val === AuthState.SignedOut){

            try {
                await Auth.signOut();
            } catch (e) {
                console.log(e);
            }

            this.state.user = null;
        }

        this.setState(this.state, () => {});
    }

    async componentDidMount(){

        try {
            const user = await Auth.currentAuthenticatedUser();

            if (user === null) {
                await this.SetAuthState(AuthState.SignedOut)
                this.state.navigate = null;
            } else{
                await this.SetAuthState(AuthState.SignedIn)
            }

            this.state.user = user;

        } catch (e) {
            console.log(e);
        }

        this.state.loading = false;
        this.setState( this.state);
    }

    async NavigateTo(navigate, talent_name) {

       if (this.state.navigate !== navigate || this.state.talent_name != talent_name) {

           this.state.loading = true;
           this.setState(this.state, () => {});

           this.state.navigate = navigate;

           if (navigate === Constants.Navigation.Logout) {

               this.state.navigate = null;
               await this.SetAuthState(AuthState.SignedOut);

               this.state.authState = AuthState.SignedOut;
           }

           this.state.loading = false;
           this.state.talent_name = talent_name;
           this.setState(this.state, () => {});
       }
    }

    render() {
        return (
            !this.state.loading?
                this.state.user?
                    <>
                    {this.state.navigate === Constants.Navigation.Content?
                        <Content user={this.state.user} loading={this.state.loading} NavigateTo={this.NavigateTo} talent_name={this.state.talent_name} />
                        :
                    this.state.navigate === Constants.Navigation.ThankYou?
                        <ThankYou user={this.state.user} loading={this.state.loading} NavigateTo={this.NavigateTo} talent_name={this.state.talent_name} />
                        :
                    !this.state.talent_name && this.state.user.signInUserSession && this.state.user.signInUserSession.idToken && this.state.user.signInUserSession.idToken.payload &&
                    this.state.user.signInUserSession.idToken.payload['cognito:groups'] &&
                    this.state.user.signInUserSession.idToken.payload['cognito:groups'].indexOf('Admin') >= 0?
                        <UsersList user={this.state.user} NavigateTo={this.NavigateTo} loading={this.state.loading} />
                        :
                        <Welcome user={this.state.user} NavigateTo={this.NavigateTo} loading={this.state.loading} talent_name={this.state.talent_name} />
                    }                    
                    <div style={{opacity: 0, display: 'none'}}>
                        <img src="assets/img/next.png" alt="" />
                        <img src="assets/img/prev.png" alt="" />
                        <img src="assets/img/icon/Twitter-logo.png" alt="" />
                        <img src="assets/img/logo-content-empty.png" alt="" />

                        <img src="assets/img/40_stanMailLogo_416x419.png" alt="" />
                        <img src="assets/img/41_stanMailLogo_700x243.png" alt="" />
                        <img src="assets/img/42_stanMailLogo_favicon_192x192.png" alt="" />
                        <img src="assets/img/43_stanMailLogo_512x512.png" alt="" />
                        
                        <img src="assets/img/38_BGGradient_Light_Background.png" alt="" />
                        <img src="assets/img/38_BGGradient_Light_Background_resize.png" alt="" />
                        <img src="assets/img/37_BGGradient_Dark_Background.png" alt="" />
                    </div>

                    </>
                    :
                    <CustomSignIn user={this.state.user} SetAuthState={this.SetAuthState} loading={this.state.loading} authState={this.state.authState} />
                : null
        )
    }
}

export default (App);
